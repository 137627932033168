import { useNavigate } from "react-router";
import CrossSign from "../../assets/images/cross-danger.png";
import logo from "../../assets/images/logo.png";
import { ROOT_LOGIN } from "../../routes/paths";

/* eslint-disable jsx-a11y/anchor-is-valid */
const AccessDenied: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="my-3 d-flex justify-content-center">
        <a>
          <img src={logo} alt="logo" className="w-200" />
        </a>
      </div>
      <div className="d-flex justify-content-center align-items-center min-h-100">
        <div className="py-5 my-5 position-relative">
          <img
            src={CrossSign}
            className="position-absolute top-minus-12 start-50  translateX-minus-50"
            style={{ zIndex: 1 }}
            alt=""
          />
          <div className="card rounded-30 py-3 w-670 ">
            <div className="card-body text-center py-5 px-4 font-inter">
              <p className="text-black fw-bold fs-40">Access Denied</p>
              <p className="text-muted fs-20 fw-semibold m-0">
                You Are Not Authorised To View This Page
              </p>

              <a href="">
                <button
                  className="fs-24 px-5 btn btn-secondary fw-medium mt-4"
                  onClick={() => navigate(ROOT_LOGIN)}
                >
                  Go Back <i className="bi bi-arrow-left fs-6 align-middle"></i>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
