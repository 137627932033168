import { Dispatch, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { AUTH_API_PREFIX, PUBLIC_API_PREFIX } from "../../utils/constants";
import { InitialRegisterState, data } from "../common/common";

const initialState: InitialRegisterState = {
  isLoading: false,
  error: null,
  planList: { data: [] },
  payload: data,
};

const slice = createSlice({
  name: "registerSlice",
  initialState,
  reducers: {
    // START LOADING
    startRegisterLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasRegisterError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PLANS FOR CLIENT
    getPlansForClient(state, action) {
      state.isLoading = false;
      state.planList = action.payload;
      state.payload = null;
    },

    //CLIENT REGISTER
    clientSignUp(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
      state.error = null;
    },
  },
});

//Reducer
export default slice.reducer;

const {
  startRegisterLoading,
  hasRegisterError,
  getPlansForClient,
  clientSignUp,
} = slice.actions;
//--------------------------------------------------------------

// get plans for clients
export function getPlans() {
  return async (dispatch: Dispatch) => {
    dispatch(startRegisterLoading());
    try {
      const response = await axiosInstance.get(
        `${PUBLIC_API_PREFIX}/get/client/plan`,
      );
      if (response.data) {
        dispatch(getPlansForClient(response.data));
      }
    } catch (error) {
      dispatch(hasRegisterError(error));
    }
  };
}

//------------------------------------------------------------------

// get plans for clients
export function registerClient(
  email: string,
  password: string,
  planId: number | undefined,
) {
  return async (dispatch: Dispatch) => {
    dispatch(startRegisterLoading());
    try {
      const response = await axiosInstance.post(`${AUTH_API_PREFIX}/signup`, {
        email: email,
        password: password,
        planId: planId,
      });
      if (response.data) {
        dispatch(clientSignUp(response.data));
      }
    } catch (error) {
      dispatch(hasRegisterError(error));
    }
  };
}

//------------------------------------------------------------------
