// Backend Stuff
export const LOCAL_BACKEND_URL = "http://localhost:8081";
export const PUBLIC_IMQ_URL = "https://dev.immigrationquestion.com";

export const ACCESS_TOKEN = "access_token";
export const ACCESS_TOKEN_PREFIX = "Bearer ";

// API Prefix
export const AUTH_API_PREFIX = "/api/auth";
export const CLIENT_API_PREFIX = "/api/client";
export const ATTORNEY_API_PREFIX = "/api/attorney";
export const PUBLIC_API_PREFIX = "/api/public";

export const ENVIRONMENT_TYPE_PROD = "production";
export const ENVIRONMENT_TYPE_DEVELOPMENT = "development";

export const CAPTCHA_SITE_KEY = "6LfT9tspAAAAAEFDo0JjhU_aioExY5PVJtbXJtvh";
