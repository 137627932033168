import { ElementType, Suspense, lazy } from "react";
// components
import { getRole } from "../auth/utils";
import AccessDenied from "../components/access-denied/accessDenied";
import LoadingScreen from "../components/loading-screen/LoadingScreen";
import { RoleType } from "../redux/common/common";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------
//role based authorisation
const WithRoleGaurd = (allowedRoles: any, Component: ElementType) => {
  return (props: any) => {
    const role = getRole();
    if (allowedRoles.includes(role)) {
      return <Component {...props} />;
    } else {
      return <AccessDenied />;
    }
  };
};

//----------------------------------------------------------------------

// auth
export const LoginPage = Loadable(lazy(() => import("../pages/login/LogIn")));

// forgot-password
export const ForgotPassword = Loadable(
  lazy(() => import("../pages/forgot-password/ForgotPassword")),
);

//reset-password page
export const ResetPassword = Loadable(
  lazy(() => import("../pages/forgot-password/ResetPassword")),
);

// register
export const SignUpPage = Loadable(
  lazy(() => import("../pages/register/Register")),
);

// lead claimed success page
export const LeadClaimSuccess = Loadable(
  lazy(() => import("../layouts/claim-lead-success/ClaimLeadPage")),
);

// home-page
export const MainLayout = Loadable(
  WithRoleGaurd(
    [RoleType.CLIENT],
    lazy(() => import("../layouts/main/MainLayout")),
  ),
);

// ask-questions
export const AskQuestions = Loadable(
  WithRoleGaurd(
    [RoleType.CLIENT],
    lazy(() => import("../layouts/ask-question/AskQuestionLayOut")),
  ),
);

// my-questions
export const MyQuestions = Loadable(
  WithRoleGaurd(
    [RoleType.CLIENT],
    lazy(() => import("../layouts/my-questions/MyQuestionLayout")),
  ),
);

// client-profile
export const ClientProfile = Loadable(
  WithRoleGaurd(
    [RoleType.CLIENT],
    lazy(() => import("../layouts/client-profile/ClientProfileLayout")),
  ),
);

// notification-page
export const AttorneyNotification = Loadable(
  WithRoleGaurd(
    [RoleType.ATTORNEY],
    lazy(
      () =>
        import("../layouts/attorney-notification/AttorneyNotificationLayout"),
    ),
  ),
);

// attorney-profile
export const AttorneyProfile = Loadable(
  WithRoleGaurd(
    [RoleType.ATTORNEY],
    lazy(() => import("../layouts/attorney-profile/AttorneyProfileLayout")),
  ),
);

// leads-detail
export const LeadsDetail = Loadable(
  WithRoleGaurd(
    [RoleType.ATTORNEY],
    lazy(() => import("../layouts/leads-details/LeadsDetailsLayout")),
  ),
);

// client-payment
export const ClientPayment = Loadable(
  lazy(() => import("../components/payment/StripePaymentPage")),
);

// payment success page
export const ClientPaymentSuccess = Loadable(
  lazy(() => import("../components/payment/PaymentSuccess")),
);

// my appointment page
export const MyAppointments = Loadable(
  WithRoleGaurd(
    [RoleType.CLIENT],
    lazy(() => import("../layouts/my-appointments/MyAppointmentLayout")),
  ),
);

// attorney appointment page
export const AttorneyAppointments = Loadable(
  WithRoleGaurd(
    [RoleType.ATTORNEY],
    lazy(
      () =>
        import("../layouts/attorney-appointments/AttorneyAppointmentsLayout"),
    ),
  ),
);

// unsubscribe email page
export const UnsubscribeIMQ = lazy(() => import("../pages/Unsubscribe"));
