import { Spinner } from "react-bootstrap";

const LoadingScreen: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </div>
  );
};

export default LoadingScreen;
