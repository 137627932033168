import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import askQuestionSlice from "./ask-question/askQuestionSlice";
import attorneyNotificationSlice from "./attorney-notifications/attorneyNotificationSlice";
import attorneySlice from "./attorney/attorneySlice";
import leadsDetailSlice from "./leads-detail/leadsDetailSlice";
import myQuestionsSlice from "./my-questions/myQuestionsSlice";
import userSlice from "./user/userSlice";
import registerSlice from "./register/registerSlice";
import myAppointmentSlice from "./my-appointments/myAppointmentSlice";
import attorneyAppointmentSlice from "./attorney-appointments/attorneyAppointmentsSlice";

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  askQuestionReducer: askQuestionSlice,
  userReducer: userSlice,
  myQuestionsReducer: myQuestionsSlice,
  attorneyReducer: attorneySlice,
  attorneyNotificationReducer: attorneyNotificationSlice,
  leadsDetailsReducerReducer: leadsDetailSlice,
  registerReducer: registerSlice,
  appointmentReducer: myAppointmentSlice,
  attorneyAppoitmentReducer: attorneyAppointmentSlice,
});

export default rootReducer;
