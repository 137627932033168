import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { CLIENT_API_PREFIX } from "../../utils/constants";
import { data, InitialUserState } from "../common/common";
import { IUser } from "./type/user";

const initialState: InitialUserState = {
  isLoading: false,
  error: null,
  statesList: { data: [] },
  payload: data,
};

const slice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    // START LOADING
    startUserLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasUserError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USER DETAILS
    getClientDetails(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    // GET ALL STATES
    getAllStates(state, action) {
      state.isLoading = false;
      state.statesList = action.payload;
      state.payload = null;
    },

    updateUserDetails(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    updateClientProfilePhoto(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },
  },
});

export default slice.reducer;

//Reducer
const {
  startUserLoading,
  hasUserError,
  getClientDetails,
  getAllStates,
  updateUserDetails,
  updateClientProfilePhoto,
} = slice.actions;

//--------------------------------------------------------------

// user details
export function getUserDetails() {
  return async (dispatch: Dispatch) => {
    dispatch(startUserLoading());
    try {
      const response = await axiosInstance.get(
        `${CLIENT_API_PREFIX}/my/detail`,
      );
      if (response.data.data) {
        dispatch(getClientDetails(response.data));
      }
    } catch (error) {
      dispatch(hasUserError(error));
    }
  };
}

//------------------------------------------------------------------
// get all states
export function getAllState() {
  return async (dispatch: Dispatch) => {
    dispatch(startUserLoading());
    try {
      const response = await axiosInstance.get(`/api/location/state`);
      if (response.data.data) {
        dispatch(getAllStates(response.data));
      }
    } catch (error) {
      dispatch(hasUserError(error));
    }
  };
}

//-------------------------------------------------------------------------
// update user details
export function updateUser(user: IUser) {
  return async (dispatch: Dispatch) => {
    dispatch(startUserLoading());
    try {
      const response = await axiosInstance.post(`${CLIENT_API_PREFIX}/update`, {
        address: user.address,
        firstName: user.firstName,
        lastName: user.lastName,
        middleName: user.middleName,
        phoneNumber: user.phoneNumber,
        state: user.state,
        zipcode: user.zipcode,
        country: user.country,
      });
      dispatch(updateUserDetails(response.data));
    } catch (error) {
      dispatch(hasUserError(error));
    }
  };
}

//----------------------------------------------------------------------------
// update client profile photo
export function updateClientProfilePicture(file: File) {
  return async (dispatch: Dispatch) => {
    const form = new FormData();
    form.append("file", file);
    dispatch(startUserLoading());
    try {
      const response = await axiosInstance.post(
        `${CLIENT_API_PREFIX}/upload/profile`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      dispatch(updateClientProfilePhoto(response.data));
    } catch (error) {
      dispatch(hasUserError(error));
    }
  };
}
