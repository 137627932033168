export const openFullscreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
};

export const formatDate = (inputDate: string): string => {
  const date = new Date(inputDate);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  const amOrPm = date.getHours() >= 12 ? "PM" : "AM";
  const minutes = date.getMinutes();

  const formattedDate = `${day} ${month} ${hours}:${minutes
    .toString()
    .padStart(2, "0")} ${amOrPm}`;

  return formattedDate;
};

export const toggleTheme = (): void => {
  const html = document.querySelector<HTMLHtmlElement>("html");

  if (html?.getAttribute("data-theme-mode") === "dark") {
    html.setAttribute("data-theme-mode", "light");
    html.setAttribute("data-header-styles", "light");
    html.setAttribute("data-menu-styles", "light");

    if (!localStorage.getItem("primaryRGB")) {
      html.setAttribute("style", "");
    }

    html.removeAttribute("data-bg-theme");
    const switcherLightTheme = document.querySelector<HTMLInputElement>(
      "#switcher-light-theme",
    );
    switcherLightTheme?.setAttribute("checked", "true");

    const switcherMenuLight = document.querySelector<HTMLInputElement>(
      "#switcher-menu-light",
    );
    switcherMenuLight?.setAttribute("checked", "true");

    document
      .querySelector<HTMLElement>("html")
      ?.style.removeProperty("--body-bg-rgb");

    //	checkOptions();

    const switcherHeaderLight = document.querySelector<HTMLInputElement>(
      "#switcher-header-light",
    );
    switcherHeaderLight?.setAttribute("checked", "true");

    switcherMenuLight?.setAttribute("checked", "true");
    switcherLightTheme?.setAttribute("checked", "true");

    const switcherBackgroundElements =
      document.querySelectorAll<HTMLInputElement>(
        "#switcher-background4, #switcher-background3, #switcher-background2, #switcher-background1, #switcher-background",
      );
    switcherBackgroundElements.forEach((element) => {
      element.checked = false;
    });

    localStorage.removeItem("ynexdarktheme");
    localStorage.removeItem("ynexMenu");
    localStorage.removeItem("ynexHeader");
    localStorage.removeItem("bodylightRGB");
    localStorage.removeItem("bodyBgRGB");

    if (localStorage.getItem("ynexlayout") !== "horizontal") {
      html.setAttribute("data-menu-styles", "dark");
    }
    html.setAttribute("data-header-styles", "light");
  } else {
    html?.setAttribute("data-theme-mode", "dark");
    html?.setAttribute("data-header-styles", "dark");

    if (!localStorage.getItem("primaryRGB")) {
      html?.setAttribute("style", "");
    }

    html?.setAttribute("data-menu-styles", "dark");

    const switcherDarkTheme = document.querySelector<HTMLInputElement>(
      "#switcher-dark-theme",
    );
    switcherDarkTheme?.setAttribute("checked", "true");

    const switcherMenuDark = document.querySelector<HTMLInputElement>(
      "#switcher-menu-dark",
    );
    switcherMenuDark?.setAttribute("checked", "true");

    const switcherHeaderDark = document.querySelector<HTMLInputElement>(
      "#switcher-header-dark",
    );
    switcherHeaderDark?.setAttribute("checked", "true");

    //	checkOptions();

    switcherMenuDark?.setAttribute("checked", "true");
    switcherHeaderDark?.setAttribute("checked", "true");
    switcherDarkTheme?.setAttribute("checked", "true");

    const switcherBackgroundElements =
      document.querySelectorAll<HTMLInputElement>(
        "#switcher-background4, #switcher-background3, #switcher-background2, #switcher-background1, #switcher-background",
      );
    switcherBackgroundElements.forEach((element) => {
      element.checked = false;
    });

    localStorage.setItem("ynexdarktheme", "true");
    localStorage.setItem("ynexMenu", "dark");
    localStorage.setItem("ynexHeader", "dark");
    localStorage.removeItem("bodylightRGB");
    localStorage.removeItem("bodyBgRGB");
  }
};

let html = document.querySelector("html");
html?.setAttribute("data-nav-layout", "vertical");
html?.setAttribute("data-vertical-style", "overlay");
const ANIMATION_DURATION = 300;

window.onresize = function (event) {
  let html = document.querySelector("html");
  if (window.innerWidth < 992) {
    html?.setAttribute("data-toggled", "close");
  } else {
    html?.removeAttribute("data-toggled");
  }
};

export const resetToggleSideMenu = () => {
  const sidebar = document.getElementById("sidebar");
  html?.removeAttribute("data-toggled");
  sidebar?.removeEventListener("mouseenter", mouseEntered);
  sidebar?.removeEventListener("mouseleave", mouseLeave);
};

export const toggleSidemenuReserve = () => {
  const sidebar = document.getElementById("sidebar");
  let mainContentDiv = document.querySelector(".main-content");

  if (window.innerWidth >= 992) {
    let verticalStyle = html?.getAttribute("data-vertical-style");
    switch (verticalStyle) {
      // closed
      case "closed":
        html?.removeAttribute("data-nav-style");
        if (html?.getAttribute("data-toggled") === "close-menu-close") {
          html.removeAttribute("data-toggled");
        } else {
          html?.setAttribute("data-toggled", "close-menu-close");
        }
        break;
      // icon-overlay
      case "overlay":
        html?.removeAttribute("data-nav-style");
        if (html?.getAttribute("data-toggled") === "icon-overlay-close") {
          sidebar?.addEventListener("mouseenter", mouseEntered);
          sidebar?.addEventListener("mouseleave", mouseLeave);
        } else {
          // html.removeAttribute("data-toggled", "icon-overlay-close");
          html?.removeAttribute("data-toggled");
          sidebar?.removeEventListener("mouseenter", mouseEntered);
          sidebar?.removeEventListener("mouseleave", mouseLeave);
        }
        break;
      // icon-text
      case "icontext":
        html?.removeAttribute("data-nav-style");
        if (html?.getAttribute("data-toggled") === "icon-text-close") {
          // html.removeAttribute("data-toggled", "icon-text-close");
          html.removeAttribute("data-toggled");
          sidebar?.removeEventListener("click", icontextOpen);
          mainContentDiv?.removeEventListener("click", icontextClose);
        } else {
          html?.setAttribute("data-toggled", "icon-text-close");
          if (window.innerWidth >= 992) {
            sidebar?.addEventListener("click", icontextOpen);
            mainContentDiv?.addEventListener("click", icontextClose);
          } else {
            sidebar?.removeEventListener("click", icontextOpen);
            mainContentDiv?.removeEventListener("click", icontextClose);
          }
        }
        break;
      // doublemenu
      case "doublemenu":
        html?.removeAttribute("data-nav-style");
        if (html?.getAttribute("data-toggled") === "double-menu-open") {
          html?.setAttribute("data-toggled", "double-menu-close");
          if (document.querySelector(".slide-menu")) {
            let slidemenu = document.querySelectorAll(".slide-menu");
            slidemenu.forEach((e) => {
              if (e.classList.contains("double-menu-active")) {
                e.classList.remove("double-menu-active");
              }
            });
          }
        } else {
          let sidemenu = document.querySelector(".side-menu__item.active");
          if (sidemenu) {
            html?.setAttribute("data-toggled", "double-menu-open");
            if (sidemenu.nextElementSibling) {
              sidemenu.nextElementSibling.classList.add("double-menu-active");
            } else {
              document
                .querySelector("html")
                ?.setAttribute("data-toggled", "double-menu-close");
            }
          }
        }
        doublemenu();
        break;
      // detached
      case "detached":
        if (html?.getAttribute("data-toggled") === "detached-close") {
          // html.removeAttribute("data-toggled", "detached-close");
          html.removeAttribute("data-toggled");
          sidebar?.removeEventListener("mouseenter", mouseEntered);
          sidebar?.removeEventListener("mouseleave", mouseLeave);
        } else {
          html?.setAttribute("data-toggled", "detached-close");
          if (window.innerWidth >= 992) {
            sidebar?.addEventListener("mouseenter", mouseEntered);
            sidebar?.addEventListener("mouseleave", mouseLeave);
          } else {
            sidebar?.removeEventListener("mouseenter", mouseEntered);
            sidebar?.removeEventListener("mouseleave", mouseLeave);
          }
        }
        break;
      // default
      case "default":
        iconOverayFn();
        html?.removeAttribute("data-toggled");

      // for making any vertical style as default
      // default:
      // iconOverayFn();
      // html.removeAttribute('data-toggled');
    }
  } else {
    document.querySelector("#responsive-overlay")?.classList.remove("active");
  }

  if (window.innerWidth < 992) {
    html?.setAttribute("data-toggled", "close");
  }
};

export const toggleSidemenu = () => {
  const sidebar = document.getElementById("sidebar");
  let mainContentDiv = document.querySelector(".main-content");
  let sidemenuType = html?.getAttribute("data-nav-layout");

  if (window.innerWidth >= 992) {
    if (sidemenuType === "vertical") {
      sidebar?.removeEventListener("mouseenter", mouseEntered);
      sidebar?.removeEventListener("mouseleave", mouseLeave);
      sidebar?.removeEventListener("click", icontextOpen);
      mainContentDiv?.removeEventListener("click", icontextClose);
      let sidemenulink = document.querySelectorAll(
        ".main-menu li > .side-menu__item",
      );
      sidemenulink.forEach((ele) =>
        ele.removeEventListener("click", doubleClickFn),
      );

      let verticalStyle = html?.getAttribute("data-vertical-style");
      switch (verticalStyle) {
        // closed
        case "closed":
          html?.removeAttribute("data-nav-style");
          if (html?.getAttribute("data-toggled") === "close-menu-close") {
            html.removeAttribute("data-toggled");
          } else {
            html?.setAttribute("data-toggled", "close-menu-close");
          }
          break;
        // icon-overlay
        case "overlay":
          html?.removeAttribute("data-nav-style");
          if (html?.getAttribute("data-toggled") === "icon-overlay-close") {
            // html.removeAttribute("data-toggled", "icon-overlay-close");
            html.removeAttribute("data-toggled");
            sidebar?.removeEventListener("mouseenter", mouseEntered);
            sidebar?.removeEventListener("mouseleave", mouseLeave);
          } else {
            if (window.innerWidth >= 992) {
              if (!localStorage.getItem("ynexlayout")) {
                html?.setAttribute("data-toggled", "icon-overlay-close");
              }
              sidebar?.addEventListener("mouseenter", mouseEntered);
              sidebar?.addEventListener("mouseleave", mouseLeave);
            } else {
              sidebar?.removeEventListener("mouseenter", mouseEntered);
              sidebar?.removeEventListener("mouseleave", mouseLeave);
            }
          }
          break;
        // icon-text
        case "icontext":
          html?.removeAttribute("data-nav-style");
          if (html?.getAttribute("data-toggled") === "icon-text-close") {
            // html.removeAttribute("data-toggled", "icon-text-close");
            html.removeAttribute("data-toggled");
            sidebar?.removeEventListener("click", icontextOpen);
            mainContentDiv?.removeEventListener("click", icontextClose);
          } else {
            html?.setAttribute("data-toggled", "icon-text-close");
            if (window.innerWidth >= 992) {
              sidebar?.addEventListener("click", icontextOpen);
              mainContentDiv?.addEventListener("click", icontextClose);
            } else {
              sidebar?.removeEventListener("click", icontextOpen);
              mainContentDiv?.removeEventListener("click", icontextClose);
            }
          }
          break;
        // doublemenu
        case "doublemenu":
          html?.removeAttribute("data-nav-style");
          if (html?.getAttribute("data-toggled") === "double-menu-open") {
            html?.setAttribute("data-toggled", "double-menu-close");
            if (document.querySelector(".slide-menu")) {
              let slidemenu = document.querySelectorAll(".slide-menu");
              slidemenu.forEach((e) => {
                if (e.classList.contains("double-menu-active")) {
                  e.classList.remove("double-menu-active");
                }
              });
            }
          } else {
            let sidemenu = document.querySelector(".side-menu__item.active");
            if (sidemenu) {
              html?.setAttribute("data-toggled", "double-menu-open");
              if (sidemenu.nextElementSibling) {
                sidemenu.nextElementSibling.classList.add("double-menu-active");
              } else {
                document
                  .querySelector("html")
                  ?.setAttribute("data-toggled", "double-menu-close");
              }
            }
          }
          doublemenu();
          break;
        // detached
        case "detached":
          if (html?.getAttribute("data-toggled") === "detached-close") {
            // html.removeAttribute("data-toggled", "detached-close");
            html.removeAttribute("data-toggled");
            sidebar?.removeEventListener("mouseenter", mouseEntered);
            sidebar?.removeEventListener("mouseleave", mouseLeave);
          } else {
            html?.setAttribute("data-toggled", "detached-close");
            if (window.innerWidth >= 992) {
              sidebar?.addEventListener("mouseenter", mouseEntered);
              sidebar?.addEventListener("mouseleave", mouseLeave);
            } else {
              sidebar?.removeEventListener("mouseenter", mouseEntered);
              sidebar?.removeEventListener("mouseleave", mouseLeave);
            }
          }
          break;
        // default
        case "default":
          iconOverayFn();
          html?.removeAttribute("data-toggled");

        // for making any vertical style as default
        // default:
        // iconOverayFn();
        // html.removeAttribute('data-toggled');
      }
      let menuclickStyle = html?.getAttribute("data-nav-style");
      switch (menuclickStyle) {
        case "menu-click":
          if (html?.getAttribute("data-toggled") === "menu-click-closed") {
            html?.removeAttribute("data-toggled");
          } else {
            html?.setAttribute("data-toggled", "menu-click-closed");
          }
          break;
        case "menu-hover":
          if (html?.getAttribute("data-toggled") === "menu-hover-closed") {
            html?.removeAttribute("data-toggled");
            setNavActive();
          } else {
            html?.setAttribute("data-toggled", "menu-hover-closed");
            clearNavDropdown();
          }
          break;
        case "icon-click":
          if (html?.getAttribute("data-toggled") === "icon-click-closed") {
            html.removeAttribute("data-toggled");
          } else {
            html?.setAttribute("data-toggled", "icon-click-closed");
          }
          break;
        case "icon-hover":
          if (html?.getAttribute("data-toggled") === "icon-hover-closed") {
            html.removeAttribute("data-toggled");
            setNavActive();
          } else {
            html?.setAttribute("data-toggled", "icon-hover-closed");
            clearNavDropdown();
          }
          break;
        //for making any horizontal style as default
        default:
      }
    }
  } else {
    if (html?.getAttribute("data-toggled") === "close") {
      html?.setAttribute("data-toggled", "open");
      let i = document.createElement("div");
      i.id = "responsive-overlay";
      if (!document.getElementById("responsive-overlay")) {
        document.body.appendChild(i);
      }

      setTimeout(() => {
        if (
          document.querySelector("html")?.getAttribute("data-toggled") ===
          "open"
        ) {
          document
            .querySelector("#responsive-overlay")
            ?.classList.add("active");
          document
            .querySelector("#responsive-overlay")
            ?.addEventListener("click", () => {
              document
                .querySelector("#responsive-overlay")
                ?.classList.remove("active");
              menuClose();
            });
        }
        window.addEventListener("resize", () => {
          if (window.screen.width >= 992) {
            document
              .querySelector("#responsive-overlay")
              ?.classList.remove("active");
          }
        });
      }, 100);
    } else {
      html?.setAttribute("data-toggled", "close");
    }
  }
};

function mouseEntered() {
  html?.setAttribute("data-icon-overlay", "open");
}
function mouseLeave() {
  html?.removeAttribute("data-icon-overlay");
}

function icontextOpen() {
  html?.setAttribute("data-icon-text", "open");
}
function icontextClose() {
  html?.removeAttribute("data-icon-text");
}

function doubleClickFn(this: any) {
  var $this = this;
  var checkElement = $this.nextElementSibling;
  if (checkElement) {
    if (!checkElement.classList.contains("double-menu-active")) {
      if (document.querySelector(".slide-menu")) {
        let slidemenu = document.querySelectorAll(".slide-menu");
        slidemenu.forEach((e) => {
          if (e.classList.contains("double-menu-active")) {
            e.classList.remove("double-menu-active");
            html?.setAttribute("data-toggled", "double-menu-close");
          }
        });
      }
      checkElement.classList.add("double-menu-active");
      html?.setAttribute("data-toggled", "double-menu-open");
    }
  }
}

function doublemenu() {
  if (window.innerWidth >= 992) {
    let sidemenulink = document.querySelectorAll(
      ".main-menu > li > .side-menu__item",
    );
    sidemenulink.forEach((ele) => {
      ele.addEventListener("click", doubleClickFn);
    });
  }
}

function iconOverayFn() {
  html?.setAttribute("data-nav-layout", "vertical");
  html?.setAttribute("data-vertical-style", "overlay");
  toggleSidemenu();
  setNavActive();
}

function setNavActive() {
  let currentPath = window.location.pathname.split("/")[0];

  currentPath =
    window.location.pathname === "/"
      ? "index.html"
      : window.location.pathname.substring(1);
  currentPath = currentPath.substring(currentPath.lastIndexOf("/") + 1);
  let sidemenuItems = document.querySelectorAll(".side-menu__item");
  sidemenuItems.forEach((e) => {
    if (currentPath === "/") {
      currentPath = "index.html";
    }
    if (e.getAttribute("href") === currentPath) {
      e.classList.add("active");
      e.parentElement?.classList.add("active");
      let parent: any = e.closest("ul");
      let parentNotMain = e.closest("ul:not(.main-menu)");
      let hasParent = true;
      // while (hasParent) {
      if (parent) {
        parent.classList.add("active");
        parent.previousElementSibling?.classList.add("active");
        parent.parentElement?.classList.add("active");

        if (parent.parentElement?.classList.contains("has-sub")) {
          let elemrntRef = parent.parentElement.parentElement?.parentElement;
          elemrntRef?.classList.add("open", "active");
          elemrntRef?.firstElementChild?.classList.add("active");
          if (elemrntRef?.children[1]) {
            // elemrntRef.children[1].style.display = "block";
            elemrntRef.children[1].setAttribute("style", "display:block;");

            Array.from(elemrntRef.children[1].children).map((element: any) => {
              if (element.classList.contains("active")) {
                //   element.children[1].style.display = "block";
                element.children[1].setAttribute("style", "display:block;");
              }
              return null;
            });
          }
        }

        if (parent.classList.contains("child1")) {
          slideDown(parent);
        }
        parent = parent.parentElement?.closest("ul");
        //
        if (parent && parent.closest("ul:not(.main-menu)")) {
          parentNotMain = parent.closest("ul:not(.main-menu)");
        }
        if (!parentNotMain) hasParent = false;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        hasParent = false;
      }
    }
    // }
  });
}

const slideDown = (target: HTMLUListElement, duration = ANIMATION_DURATION) => {
  const { parentElement } = target;
  parentElement?.classList.add("open");
  target.style.removeProperty("display");
  let { display } = window.getComputedStyle(target);
  if (display === "none") display = "block";
  target.style.display = display;
  const height = target.offsetHeight;
  target.style.overflow = "hidden";
  target.style.height = "0";
  target.style.paddingTop = "0";
  target.style.paddingBottom = "0";
  target.style.marginTop = "0";
  target.style.marginBottom = "0";
  //   target.offsetHeight;
  target.style.boxSizing = "border-box";
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = `${duration}ms`;
  target.style.height = `${height}px`;
  target.style.removeProperty("padding-top");
  target.style.removeProperty("padding-bottom");
  target.style.removeProperty("margin-top");
  target.style.removeProperty("margin-bottom");
  window.setTimeout(() => {
    target.style.removeProperty("height");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-property");
    target.style.removeProperty("transition-duration");
  }, duration);
};

function clearNavDropdown() {
  let sidemenus = document.querySelectorAll("ul.slide-menu");
  sidemenus.forEach((e) => {
    let parent = e.closest("ul") as HTMLUListElement;
    let parentNotMain = e.closest("ul:not(.main-menu)");
    if (parent) {
      let hasParent = parent.closest("ul.main-menu");
      while (hasParent) {
        parent?.classList.add("active");
        slideUp(parent);
        //
        parent = parent.parentElement?.closest("ul") as HTMLUListElement;
        parentNotMain = parent.closest("ul:not(.main-menu)");
        if (!parentNotMain) hasParent = null;
      }
    }
  });
}

const slideUp = (target: HTMLUListElement, duration = ANIMATION_DURATION) => {
  const { parentElement } = target;
  parentElement?.classList.remove("open");
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = `${duration}ms`;
  target.style.boxSizing = "border-box";
  target.style.height = `${target.offsetHeight}px`;
  //   target.offsetHeight;
  target.style.overflow = "hidden";
  target.style.height = "0";
  target.style.paddingTop = "0";
  target.style.paddingBottom = "0";
  target.style.marginTop = "0";
  target.style.marginBottom = "0";
  window.setTimeout(() => {
    target.style.display = "none";
    target.style.removeProperty("height");
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-duration");
    target.style.removeProperty("transition-property");
  }, duration);
};

function menuClose() {
  html?.setAttribute("data-toggled", "close");
  document.querySelector("#responsive-overlay")?.classList.remove("active");
}

export const formatDateInMMDDYYYY = (inputDate: string): string => {
  const date = new Date(inputDate);
  const formattedDate =
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "-" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "-" +
    date.getFullYear() +
    " " +
    date.getHours() +
    ":" +
    (date.getMinutes() > 10 ? date.getMinutes() : "0" + date.getMinutes()) +
    ":" +
    (date.getSeconds() > 10 ? date.getSeconds() : "0" + date.getSeconds());
  return formattedDate;
};

export const formatDateInMMDDYYYYWithOutTime = (date: Date) => {
  const formattedDate =
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear();
  return formattedDate;
};
