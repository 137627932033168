import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
// components
import LoadingScreen from "../components/loading-screen/LoadingScreen";
import { ROOT_IMMIGRATION_QUESTION } from "../routes/paths";
import { useAuthContext } from "./useAuthContext";
//

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();

  if (isAuthenticated) {
    return <Navigate to={ROOT_IMMIGRATION_QUESTION} />;
  }
  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
