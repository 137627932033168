import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { CLIENT_API_PREFIX } from "../../utils/constants";
import { data, InitialMyQuestionsState } from "../common/common";

const initialState: InitialMyQuestionsState = {
  isLoading: false,
  error: null,
  questionList: { data: [] },
  payload: data,
  isReviewSend: false,
};

const slice = createSlice({
  name: "myQuestionsslice",
  initialState,
  reducers: {
    // START LOADING
    startMyQuestionsLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasMyQuestionsError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MY QUESTIONS
    getMyQuestionsHistory(state, action) {
      state.isLoading = false;
      state.questionList = action.payload;
      state.payload = null;
    },

    // ADD REVIEW
    addReviewForQuery(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
      state.isReviewSend = true;
    },
  },
});

//Reducer
export default slice.reducer;

const {
  startMyQuestionsLoading,
  hasMyQuestionsError,
  getMyQuestionsHistory,
  addReviewForQuery,
} = slice.actions;

//--------------------------------------------------------------

// get my questions list
export function getMyQuestions() {
  return async (dispatch: Dispatch) => {
    dispatch(startMyQuestionsLoading());
    try {
      const response = await axiosInstance.get(
        `${CLIENT_API_PREFIX}/my/questions`,
      );
      if (response.data.data) {
        dispatch(getMyQuestionsHistory(response.data));
      }
    } catch (error) {
      dispatch(hasMyQuestionsError(error));
    }
  };
}

//---------------------------------------------------------------
//send review for query
export function sendQueryReview(leadId: number, review: string) {
  return async (dispatch: Dispatch) => {
    dispatch(startMyQuestionsLoading());
    try {
      const response = await axiosInstance.post(
        `${CLIENT_API_PREFIX}/lead/review`,
        {
          leadId: leadId,
          review: review,
        },
      );
      dispatch(addReviewForQuery(response.data));
    } catch (error) {
      dispatch(hasMyQuestionsError(error));
    }
  };
}
