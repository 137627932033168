import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from "react";
// utils
//
import toast from "react-hot-toast";
import axiosInstance from "../utils/axios";
import * as Constants from "../utils/constants";
import localStorageAvailable from "../utils/localStorageAvailable";
import { ActionMapType, AuthStateType, JWTContextType } from "./types";
import { isValidToken, setSession } from "./utils";
// ----------------------------------------------------------------------

enum Types {
  INITIAL = "INITIAL",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  RESET = "RESET",
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    verified: boolean;
  };
  [Types.PAYMENT_PENDING]: {
    isPaymentPending: boolean;
    isSubscriptionExpired: boolean;
    data: any;
  };
  [Types.LOGIN]: {
    isAuthenticated: boolean;
    needDetail: boolean;
    userProfile: string;
    enableMylsLink: boolean;
    verified: boolean;
  };
  [Types.LOGOUT]: undefined;
  [Types.RESET]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  needDetail: true,
  isPaymentPending: false,
  data: null,
  isSubscriptionExpired: false,
  userProfile: "",
  enableMylsLink: false,
  verified: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      verified: action.payload.verified,
    };
  }
  if (action.type === Types.PAYMENT_PENDING) {
    return {
      ...state,
      isPaymentPending: action.payload.isPaymentPending,
      isSubscriptionExpired: action.payload.isSubscriptionExpired,
      data: action.payload.data,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: action.payload.isAuthenticated,
      needDetail: action.payload.needDetail,
      userProfile: action.payload.userProfile,
      enableMylsLink: action.payload.enableMylsLink,
      verified: action.payload.verified,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
    };
  }
  if (action.type === Types.RESET) {
    return {
      ...state,
      isAuthenticated: false,
      needDetail: true,
      isPaymentPending: false,
      isSubscriptionExpired: false,
      data: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem("accessToken")
        : "";
      const isAttorneyVerified = storageAvailable
        ? localStorage.getItem("isVerified")
        : "";

      if (accessToken && isValidToken(accessToken) && isAttorneyVerified) {
        setSession(accessToken, isAttorneyVerified);
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            verified: isAttorneyVerified === "true" ? true : false,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            verified: true,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          verified: true,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const response = await axiosInstance.post(
      `${Constants.AUTH_API_PREFIX}/signin`,
      {
        email: email,
        password: password,
      },
    );
    const {
      needDetail,
      token,
      status,
      success,
      data,
      message,
      userProfile,
      enableMylsLink,
      verified,
    } = response.data;
    if (!success && status === 404) {
      toast.error(message, {
        id: "unabletologin",
        duration: 4000,
      });
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          verified: true,
        },
      });
    } else if (!success && status === 402 && data) {
      dispatch({
        type: Types.PAYMENT_PENDING,
        payload: {
          isPaymentPending: true,
          isSubscriptionExpired: false,
          data: data,
        },
      });
    } else if (!success && status === 410) {
      dispatch({
        type: Types.PAYMENT_PENDING,
        payload: {
          isPaymentPending: false,
          isSubscriptionExpired: true,
          data: null,
        },
      });
    } else if (needDetail && token) {
      setSession(token, verified);
      dispatch({
        type: Types.LOGIN,
        payload: {
          isAuthenticated: true,
          needDetail: true,
          userProfile: userProfile,
          enableMylsLink: enableMylsLink,
          verified: verified,
        },
      });
    } else if (!needDetail && token) {
      setSession(token, verified);
      dispatch({
        type: Types.LOGIN,
        payload: {
          isAuthenticated: true,
          needDetail: false,
          userProfile: userProfile,
          enableMylsLink: enableMylsLink,
          verified: verified,
        },
      });
    }
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null, null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // RESET
  const resetAuthState = useCallback(() => {
    setSession(null, null);
    dispatch({
      type: Types.RESET,
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      needDetail: state.needDetail,
      isPaymentPending: state.isPaymentPending,
      isSubscriptionExpired: state.isSubscriptionExpired,
      data: state.data,
      method: "jwt",
      userProfile: state.userProfile,
      enableMylsLink: state.enableMylsLink,
      verified: state.verified,
      login,
      logout,
      resetAuthState,
    }),
    [
      state.isInitialized,
      state.isAuthenticated,
      state.needDetail,
      state.isPaymentPending,
      state.isSubscriptionExpired,
      state.data,
      state.userProfile,
      state.enableMylsLink,
      state.verified,
      login,
      logout,
      resetAuthState,
    ],
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
