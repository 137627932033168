import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { ATTORNEY_API_PREFIX } from "../../utils/constants";
import { data, InitialAttorneyState, listData } from "../common/common";
import { IAttorney } from "./types/attorney";

const initialState: InitialAttorneyState = {
  isLoading: false,
  error: null,
  list: listData,
  payload: data,
  tenantNameAvailability: data,
  tenantCreation: null,
  isTenantAvailableInMyls: data,
};

const slice = createSlice({
  name: "attorneySlice",
  initialState,
  reducers: {
    // START LOADING
    startAttorneyLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasAttorneyError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ATTORNEY DETAILS
    getAttorneyDetail(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
      state.tenantCreation = null;
      state.isTenantAvailableInMyls = null;
    },

    // UPDATE ATTORNEY DETAILS
    updateAttorneyDetail(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    // UPLOAD FILE FOR ATTORNEY
    uploadFileForAttorney(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    //UPLOAD PROFILE PHOTO FOR ATTORNEY
    updateAttorneyProfilePhoto(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    // CHECK TENANT AVAILABILITY
    isTenantAvailable(state, action) {
      state.isLoading = false;
      state.tenantNameAvailability = action.payload;
    },

    // TENANT CREATION
    createTenantForFirm(state, action) {
      state.isLoading = false;
      state.tenantCreation = action.payload;
    },

    // TENANT CHECKING WITH MYLS DETAILS
    isTenantExistOnMyls(state, action) {
      state.isLoading = false;
      state.isTenantAvailableInMyls = action.payload;
    },
  },
});

//Reducer
export default slice.reducer;

const {
  getAttorneyDetail,
  hasAttorneyError,
  startAttorneyLoading,
  updateAttorneyDetail,
  uploadFileForAttorney,
  updateAttorneyProfilePhoto,
  isTenantAvailable,
  createTenantForFirm,
  isTenantExistOnMyls,
} = slice.actions;

//--------------------------------------------------------------

// get attorney details
export function getAttorneyDetails() {
  return async (dispatch: Dispatch) => {
    dispatch(startAttorneyLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/my/detail`,
      );
      if (response.data.data) {
        dispatch(getAttorneyDetail(response.data));
      }
    } catch (error) {
      dispatch(hasAttorneyError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------

// update attorney details
export function updateAttorneyDetails(attorney: IAttorney) {
  return async (dispatch: Dispatch) => {
    dispatch(startAttorneyLoading());
    try {
      const response = await axiosInstance.post(
        `${ATTORNEY_API_PREFIX}/update`,
        {
          bar: attorney.bar,
          firstName: attorney.firstName,
          lastName: attorney.lastName,
          lawFirmName: attorney.firmName,
          officeAddress: attorney.officeAddress,
          cellPhoneNumber: attorney.cellPhone,
          officeState: attorney.statesOfPracticeArea,
          officeZipcode: attorney.postalCode,
          practiceAreas: attorney.practiceAreas,
          consultationFeePerHour: attorney.consultationFeePerHour,
          website: attorney.website,
          yearOfCall: attorney.yearOfCall,
          spokenLanguage: attorney.spokenLanguage,
          lawyerInBarCommitee: attorney.lawyerInBarCommitee,
          haveAMyLSAccount: attorney.haveAMyLSAccount,
          tenantName: attorney.tenantName,
          mylsUsername: attorney.mylsUsername,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      dispatch(updateAttorneyDetail(response.data));
    } catch (error) {
      dispatch(hasAttorneyError(error));
    }
  };
}

//-----------------------------------------------------------------------------------------------------------------------------

//upload attorney file data
export const uploadAttorneyFile =
  (document: File) => async (dispatch: Dispatch) => {
    const form = new FormData();
    form.append("file", document);
    dispatch(startAttorneyLoading());
    try {
      const response = await axiosInstance.post(
        `${ATTORNEY_API_PREFIX}/upload/document`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      dispatch(uploadFileForAttorney(response.data));
    } catch (error) {
      dispatch(hasAttorneyError(error));
    }
  };

//----------------------------------------------------------------------------

// update client profile photo
export function updateAttorneyProfilePicture(file: File) {
  return async (dispatch: Dispatch) => {
    const form = new FormData();
    form.append("file", file);
    dispatch(startAttorneyLoading());
    try {
      const response = await axiosInstance.post(
        `${ATTORNEY_API_PREFIX}/upload/profile`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      dispatch(updateAttorneyProfilePhoto(response.data));
    } catch (error) {
      dispatch(hasAttorneyError(error));
    }
  };
}

//----------------------------------------------------------------------------

// checking tenant availability
export function checkingTenantAvailability(tenantName: string) {
  return async (dispatch: Dispatch) => {
    dispatch(startAttorneyLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/check/tenant/available?tenantName=${tenantName}`,
      );
      dispatch(isTenantAvailable(response.data));
    } catch (error) {
      dispatch(hasAttorneyError(error));
    }
  };
}

//----------------------------------------------------------------------------

// update attorney details
export function createTenant(tenantName: string) {
  return async (dispatch: Dispatch) => {
    dispatch(startAttorneyLoading());
    try {
      const response = await axiosInstance.post(
        `${ATTORNEY_API_PREFIX}/tenant/creation?tenantName=${tenantName}`,
      );
      dispatch(createTenantForFirm(response.data));
    } catch (error) {
      dispatch(hasAttorneyError(error));
    }
  };
}

//-----------------------------------------------------------------------------------------------------------------------------

// checking tenant existence on myls
export function checkTenantExistanceOnMyLS(
  username: string,
  password: string,
  tenantName: string,
) {
  return async (dispatch: Dispatch) => {
    dispatch(startAttorneyLoading());
    try {
      const response = await axiosInstance.post(
        `${ATTORNEY_API_PREFIX}/tenant/exist?tenantName=${tenantName}`,
        {
          username: username,
          password: password,
        },
      );
      dispatch(isTenantExistOnMyls(response.data));
    } catch (error) {
      dispatch(hasAttorneyError(error));
    }
  };
}

//-----------------------------------------------------------------------------------------------------------------------------
