import * as Constant from "./utils/constants";

const env = process.env;

const api_url =
  env.NODE_ENV === Constant.ENVIRONMENT_TYPE_PROD
    ? env.REACT_APP_DEV_API_URL
    : Constant.LOCAL_BACKEND_URL;

const public_imq_uri =
  env.NODE_ENV === Constant.ENVIRONMENT_TYPE_PROD
    ? env.REACT_APP_IMQ_PUBLIC_PORTAL_URL
    : Constant.PUBLIC_IMQ_URL;

export const environment = {
  environmentUri: api_url,
  publicImqUri: public_imq_uri,
};

console.log("environment", environment.environmentUri);
