import { IPracticeAreas } from "../ask-question/types/practiceArea";
import {
  IAppointmentCount,
  IAssignedAppointmentList,
} from "../attorney-appointments/attorneyAppointments";
import { ILeads } from "../attorney-notifications/types/leads";
import { IAttorney } from "../attorney/types/attorney";
import { IAssignedLeads } from "../leads-detail/type/assignedLeads";
import { IClaimedLeads } from "../leads-detail/type/claimedLeads";
import { ILeadsCount } from "../leads-detail/type/count";
import { IReviewedLeads } from "../leads-detail/type/reviewedLeads";
import {
  IAppointmentList,
  IScheduleAppointment,
} from "../my-appointments/type/appointment";
import { IMyQuestion } from "../my-questions/type/myQuestion";
import { IPlan } from "../register/type/plan";
import { IStates } from "../user/type/location";
import { IUser } from "../user/type/user";

//-----------------------------------------------------------------------------------

export type FilterPayload = {
  pageNo: number;
  pageSize: number;
  sortBy: string;
};

export type InitialState = {
  isLoading: boolean;
  error: any;
  list: DataList | null;
  payload: any;
};

export type InitialUserState = {
  isLoading: boolean;
  error: any;
  statesList: IStateListData;
  payload: any;
};

export type InitialAttorneyState = {
  isLoading: boolean;
  error: any;
  list: DataList | null;
  tenantNameAvailability: IData | null;
  payload: any;
  tenantCreation: IData | null;
  isTenantAvailableInMyls: IData | null;
};

export type InitialAskQuestionState = {
  isLoading: boolean;
  error: any;
  practiceAreaList: IPracticeAreasListData;
  payload: any;
};

export type InitialAttorneyNotificationState = {
  isLoading: boolean;
  error: any;
  pendingReferralList: IPendingReferralListData;
  appointmentReferralList: IAppointmentReferralListData;
  payload: any;
};

export type InitialLeadsDetailsState = {
  isLoading: boolean;
  error: any;
  assignedLeadsList: IAssignedLeadsListData;
  claimedLeadsList: IClaimedLeadsListData;
  getReviewedLeadsList: IReviewedLeadsListData;
  leadsCount: ILeadsCountData;
  payload: any;
};

export type InitialMyQuestionsState = {
  isLoading: boolean;
  error: any;
  questionList: IMyQuestionListData;
  payload: any;
  isReviewSend: boolean;
};

export type InitialRegisterState = {
  isLoading: boolean;
  error: any;
  planList: IPlanListData;
  payload: any;
};

export type InitialMyAppointmentState = {
  isLoading: boolean;
  error: any;
  payload: any;
  appointmentListForClient: IAppointmentListData;
  isAppointmentCrated: boolean;
};

export type InitialAttorneyAppointmentState = {
  isLoading: boolean;
  error: any;
  payload: any;
  assignedAppointmentList: IAssignedAppointmentListData;
  acceptedAppointmentList: IAcceptedAppointmentListData;
  appointmentCount: IAppointmentCountData;
};

//-------------------------------------------------------------------------------------------------

export type DataList = {
  data: Array<IPracticeAreas | IStates | IMyQuestion | IAttorney | any>;
  totalRows: number;
};

export type IPracticeAreasListData = {
  data: IPracticeAreas[];
};

export type IStateListData = {
  data: IStates[];
};

export type IPendingReferralListData = {
  data: ILeads[];
  total: number;
};

export type IAppointmentReferralListData = {
  data: IAppointmentList[];
  total: number;
};

export type IAssignedLeadsListData = {
  data: IAssignedLeads[];
};

export type IClaimedLeadsListData = {
  data: IClaimedLeads[];
};

export type IReviewedLeadsListData = {
  data: IReviewedLeads[];
};

export type ILeadsCountData = {
  data: ILeadsCount;
};

export type IMyQuestionListData = {
  data: IMyQuestion[];
};

export type IPlanListData = {
  data: IPlan[];
};

export type IAppointmentListData = {
  data: IAppointmentList[];
};

export type IAssignedAppointmentListData = {
  data: IAssignedAppointmentList[];
};

export type IAcceptedAppointmentListData = {
  data: IAppointmentList[];
};

export type IAppointmentCountData = {
  data: IAppointmentCount;
};

//-------------------------------------------------------------------------------------------------

export type IData = {
  data:
    | IPracticeAreas
    | IStates
    | IMyQuestion
    | IAttorney
    | IUser
    | IScheduleAppointment
    | number
    | boolean;
  description: string;
  message: string;
  status: number;
  success: boolean;
};

export const listData: DataList | null = null;
export const data: IData | null = null;

export enum RoleType {
  CLIENT = "CLIENT",
  ATTORNEY = "ROLE_ATTORNEY",
}
