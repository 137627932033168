import axios from "axios";
// config
import { environment } from "../environment";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: environment.environmentUri });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong",
    ),
);

export default axiosInstance;
