import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { ATTORNEY_API_PREFIX } from "../../utils/constants";
import {
  data,
  FilterPayload,
  InitialAttorneyNotificationState,
} from "../common/common";

const initialState: InitialAttorneyNotificationState = {
  isLoading: false,
  error: null,
  pendingReferralList: { data: [], total: 0 },
  appointmentReferralList: { data: [], total: 0 },
  payload: data,
};

const slice = createSlice({
  name: "attorneyNotificationSlice",
  initialState,
  reducers: {
    // START LOADING
    startAttorneyNotificationsLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasAttorneyNotificationsError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PENDING REFERRALS
    getpendingReferralsForAttorney(state, action) {
      state.isLoading = false;
      state.pendingReferralList = action.payload;
      state.payload = null;
    },

    // GET APPOINTMENT REFERRALS
    getAppointmentReferralsForAttorney(state, action) {
      state.isLoading = false;
      state.appointmentReferralList = action.payload;
      state.payload = null;
    },
  },
});

//Reducer
export default slice.reducer;

const {
  startAttorneyNotificationsLoading,
  hasAttorneyNotificationsError,
  getpendingReferralsForAttorney,
  getAppointmentReferralsForAttorney,
} = slice.actions;

//--------------------------------------------------------------

// get pending referral
export function getPendingReferrals(payload: FilterPayload) {
  return async (dispatch: Dispatch) => {
    dispatch(startAttorneyNotificationsLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/pending/referrals`,
        {
          params: payload,
        },
      );
      if (response.data.data) {
        dispatch(getpendingReferralsForAttorney(response.data.data));
      }
    } catch (error) {
      dispatch(hasAttorneyNotificationsError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------

// get appointment referral
export function getAppointmentReferrals(payload: FilterPayload) {
  return async (dispatch: Dispatch) => {
    dispatch(startAttorneyNotificationsLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/appointment/referrals`,
        {
          params: payload,
        },
      );
      if (response.data.data) {
        dispatch(getAppointmentReferralsForAttorney(response.data.data));
      }
    } catch (error) {
      dispatch(hasAttorneyNotificationsError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------
