import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from "react-hot-toast";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import { AuthProvider } from "./auth/JwtContext";
import { persister, store } from "./redux/store";
import Router from "./routes/routes";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persister}>
          <BrowserRouter>
            <Router />
            <Toaster position="top-right" toastOptions={{ duration: 1500 }} />
          </BrowserRouter>
        </PersistGate>
      </ReduxProvider>
    </AuthProvider>
  );
};

export default App;
