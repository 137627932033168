import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
import GreenTick from "../assets/images/check-success.png";
import logo from "../assets/images/logo.png";
import { environment } from "../environment";
import { ROOT_LOGIN } from "../routes/paths";
import axiosInstance from "../utils/axios";

/* eslint-disable jsx-a11y/anchor-is-valid */
const Unsubscribe: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState<string | null>(null);
  const [subscribed, setSubscribed] = useState<boolean | null>(null);

  const unsubscribeImq = useCallback(async (userId: string) => {
    try {
      const response = await axiosInstance.put(
        `${environment.environmentUri}/api/public/unsubscribe?client=${userId}`,
      );
      if (response.data) {
        const message = response.data.message;
        if (response.data.success) {
          setSubscribed(false);
          toast.success(message, {
            id: "unsubscribesuccess",
            duration: 4000,
          });
        } else {
          toast(message, {
            style: {
              background: "#e65c00",
              color: "#fff",
            },
            id: "jkslsfsl",
            duration: 4000,
          });
        }
      }
    } catch (error: any) {
      toast.error(error, {
        id: "sfsaisd",
        duration: 4000,
      });
    }
  }, []);

  const subscribeImq = async (user: string) => {
    try {
      const response = await axiosInstance.put(
        `${environment.environmentUri}/api/public/subscribe?client=${user}`,
      );
      if (response.data) {
        const message = response.data.message;
        if (response.data.success) {
          setSubscribed(true);
          setUser(null);
          toast.success(message, {
            id: "subscribesuccess",
            duration: 4000,
          });
        } else {
          toast(message, {
            style: {
              background: "#e65c00",
              color: "#fff",
            },
            id: "sdfsadsfs",
            duration: 4000,
          });
        }
      }
    } catch (error: any) {
      toast.error(error, {
        id: "fdfsfd",
        duration: 4000,
      });
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get("client");
    // condition for api call(subscribe api impemented in mail)
    const unsubscribe = window.location.href.includes("unsubscribe");
    if (userId) {
      setUser(userId);
      if (unsubscribe) {
        unsubscribeImq(userId);
      } else {
        subscribeImq(userId);
      }
    }
  }, [location.search, unsubscribeImq]);

  return (
    <>
      <div className="menu-bg">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <div className="container">
            <a className="navbar-brand">
              <img src={logo} alt="logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                <li className="nav-item me-4 font-Montserrat fs-14">
                  <a
                    className="nav-link text-white text-uppercase active"
                    aria-current="page"
                    href={environment.publicImqUri}
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item me-4 font-Montserrat fs-14">
                  <a
                    className="nav-link text-white text-uppercase "
                    aria-current="page"
                    href={environment.publicImqUri}
                  >
                    how it works
                  </a>
                </li>
                <li className="nav-item me-4 font-Montserrat fs-14">
                  <a
                    className="nav-link text-white  text-uppercase"
                    aria-current="page"
                    href={environment.publicImqUri}
                  >
                    areas of law
                  </a>
                </li>
                <li className="nav-item me-4 font-Montserrat fs-14">
                  <a
                    className="nav-link text-white  text-uppercase"
                    aria-current="page"
                    href={environment.publicImqUri}
                  >
                    news
                  </a>
                </li>
                <li className="nav-item font-Montserrat fs-14">
                  <a
                    className="nav-link text-white  text-uppercase"
                    aria-current="page"
                    href={environment.publicImqUri}
                  >
                    FAQs
                  </a>
                </li>
              </ul>
              <form className="d-flex">
                <button
                  className="btn btn-primary px-5 rounded-pill"
                  type="button"
                  onClick={() => navigate(ROOT_LOGIN)}
                >
                  LOGIN
                </button>
              </form>
            </div>
          </div>
        </nav>
      </div>
      {subscribed === false && user ? (
        <div className="container">
          <div className="d-flex justify-content-center align-items-center min-h-100">
            <div className="py-5 my-5 position-relative">
              <img
                src={GreenTick}
                className="position-absolute top-minus-12 start-50  translateX-minus-50"
                style={{ zIndex: 1 }}
                alt=""
              />
              <div className="card rounded-30 py-3 w-670 ">
                <div className="card-body text-center py-5 px-4 font-inter">
                  <p className="text-black fw-bold fs-40">
                    Successfully Unsubscribed
                  </p>
                  <p className="text-muted fs-20 fw-semibold m-0">
                    Sad to Hear that you are Leaving Us.
                  </p>

                  <a>
                    <button
                      className="fs-24 px-5 btn btn-secondary fw-medium mt-4"
                      onClick={() => subscribeImq(user)}
                    >
                      Subscribe{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        subscribed === true && (
          <div className="container">
            <div className="d-flex justify-content-center align-items-center min-h-100">
              <div className="py-5 my-5 position-relative">
                <img
                  src={GreenTick}
                  className="position-absolute top-minus-12 start-50  translateX-minus-50"
                  style={{ zIndex: 1 }}
                  alt=""
                />
                <div className="card rounded-30 py-3 w-670 ">
                  <div className="card-body text-center py-5 px-4 font-inter">
                    <p className="text-black fw-bold fs-40">
                      Subscribed to IMQ Successfully
                    </p>
                    <p className="text-muted fs-20 fw-semibold m-0">
                      We are glad that you've changed your mind.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
      <div className="bg-primary py-2 text-center">
        <p className="m-0 fs-14 container fw-medium text-black">
          © 2023 - Immigration Question by MyLegalSoftware - All Rights
          Reserved.
        </p>
      </div>
    </>
  );
};

export default Unsubscribe;
