import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { data, InitialAskQuestionState } from "../common/common";
import { IQuestionDetails } from "./types/questionDetails";

const initialState: InitialAskQuestionState = {
  isLoading: false,
  error: null,
  practiceAreaList: { data: [] },
  payload: data,
};

const slice = createSlice({
  name: "askQuestionSlice",
  initialState,
  reducers: {
    // START LOADING
    startAskQuestionLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasAskQuestionError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ALL PRACTICE AREAS
    getPracticeAreas(state, action) {
      state.isLoading = false;
      state.practiceAreaList = action.payload;
      state.payload = null;
    },

    // CREATE LEAD
    createNewLead(state, action) {
      state.isLoading = false;
      state.practiceAreaList = { data: [] };
      state.payload = action.payload;
    },
  },
});

//Reducer
export default slice.reducer;

const {
  startAskQuestionLoading,
  hasAskQuestionError,
  getPracticeAreas,
  createNewLead,
} = slice.actions;

//--------------------------------------------------------------

// get all practice area
export function getAllPracticeAreas() {
  return async (dispatch: Dispatch) => {
    dispatch(startAskQuestionLoading());
    try {
      const response = await axiosInstance.get(`/api/practice-area/all`);
      if (response.data.data) {
        dispatch(getPracticeAreas(response.data));
      }
    } catch (error) {
      dispatch(hasAskQuestionError(error));
    }
  };
}

// ask question
export function createLead(questionDetails: IQuestionDetails) {
  return async (dispatch: Dispatch) => {
    dispatch(startAskQuestionLoading());
    try {
      const response = await axiosInstance.post(`/api/lead/create`, {
        consumerQuery: questionDetails.consumerQuery,
        practiceArea: questionDetails.practiceAreaSelected,
      });
      dispatch(createNewLead(response.data));
    } catch (error) {
      dispatch(hasAskQuestionError(error));
    }
  };
}
